<template>
  <div class="job_card">
    <div class="job_card_inner">
      <div class="job_title" @click="goJobEdit">
        {{ item.postName }}
        <div class="m">{{ item.month }}</div>
      </div>
      <div class="job_brief">
        <div class="job_req">
          {{ item.provinceName }}{{ item.cityName }} ｜
          {{ item.experienceLow }}-{{ item.experienceHigh }}年 ｜
          {{ userStore.getDegree(item.education) }}
        </div>
        <div class="job_salary">
          <span v-if="item.salType === 1"
            >{{ item.salLow }}-{{ item.salHigh }}K&nbsp;{{
              item.salMonths
            }}薪</span
          >
          <span v-else-if="item.salType === 2">{{
            userStore.SALARY_TALK
          }}</span>
          <span v-else-if="item.salType === 3"
            >{{ item.salLow }}-{{ item.salHigh }}/天</span
          >
        </div>
      </div>
      <div class="tag_list">
        <div class="tag" :key="item" v-for="item in keywords">{{ item }}</div>
        <!--      <div class="tag">视觉设计</div>-->
        <!--      <div class="tag">视觉设计</div>-->
        <!--      <div class="tag">视觉设计</div>-->
      </div>
      <div class="job_desc">
        {{ item.postDescription }}
      </div>
      <div class="card_foot">
        <div class="dj_wrapper">
          <img
            class="dj"
            v-for="(item, index) in imgList"
            :key="index"
            :style="{ left: -12 * index + 'px' }"
            :src="item"
          />
          <div
            class="dj dj_add"
            @click="goRecommend"
            :style="{ left: -12 * imgList.length + 'px' }"
          >
            +
          </div>
        </div>
        <div class="candidate">{{ item.candidateCount }}候选人</div>
      </div>
    </div>
  </div>
</template>

<script>
// import avatar from "@/assets/img/test_photo.svg";
import { mapStores } from "pinia";
import { useUserStore } from "@/store/user/user";
import { keywordSplit } from "@/util/util";
export default {
  name: "jobCard",
  props: ["item"],
  data() {
    return {
      imgList: [],
      keywords: [],
      avatars: [],
    };
  },
  methods: {
    goJobEdit() {
      this.$router.push({
        path: "/editPosition",
        query: {
          id: this.item.companyPostId,
        },
      });
    },
    goRecommend() {
      this.$router.push({
        path: "/recommendList",
        query: {
          id: this.item.companyPostId,
        },
      });
    },
  },
  mounted() {
    this.imgList = this.item.avatarUrls ? this.item.avatarUrls.split(",") : [];

    // this.item.keyWords.split(" ").forEach((v)=>{
    //   if(v.trim()!=""){
    //     this.keywords.push(v);
    //   }
    // });
    if (this.item.keyWords) {
      this.keywords = keywordSplit(this.item.keyWords);
    }
  },
  computed: {
    ...mapStores(useUserStore),
  },
};
</script>

<style lang="less">
.job_card {
  width: 386px;
  height: 289px;
  border-radius: 15px;
  background-color: #ffffff;
  overflow: hidden;
  .job_brief {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    .job_req {
      font-size: 14px;
      color: #999999;
    }
    .job_salary {
      font-size: 16px;
      color: #ff6400;
    }
  }
  .tag_list {
    margin-top: 15px;
  }
  .job_title {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #1a1a1a;
    cursor: pointer;
    .m {
      font-size: 16px;
      color: #666666;
    }
  }
  .job_desc {
    font-size: 14px;
    margin-top: 25px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #666666;
  }
  .job_card_inner {
    margin: 40px;
  }
  .card_foot {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .dj_wrapper {
      display: flex;
      .dj {
        width: 25px;
        height: 25px;
        border-radius: 13px;
        border: 2px solid #f0f0f0;
        position: relative;
        background-color: #fff;
      }
      .dj_add {
        line-height: 25px;
        cursor: pointer;
        background-color: #f0f0f0;
        font-size: 16px;
        color: #666666;
        text-align: center;
      }
    }
    .candidate {
      font-size: 12px;
      color: #999999;
      display: flex;
      align-items: center;
    }
  }
}
</style>
