<template>
<div class="page_my_position">
  <div class="inner" style="min-width:1580px;">
    <div class="filter_area">
      <el-form :inline="true">
        <el-form-item label="排序方式:">
          <el-select v-model="param.orderByName">
            <el-option
                v-for="item in sortList"
                :key="item.label"
                :value="item.value"
                :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
<!--        <el-form-item label="创建日期:">-->
<!--          <el-date-picker-->
<!--              type="date"-->
<!--              placeholder="Pick a day"-->
<!--          />-->
<!--        </el-form-item>-->
        <el-form-item label="职位类型:" v-if="loadComplete">
<!--          <el-select>-->
<!--          </el-select>-->
          <el-cascader
              style="width:100%;"
              :props="positionProp"
              @change="changePosition"
              v-model="position" :show-all-levels="false"></el-cascader>
        </el-form-item>
      </el-form>
    </div>
    <div class="filter_area">
      <div class="filter_inner">
        <div style="display:flex;">
          <div class="filter_item" :class="{filter_item_cur:param.postStatus === 0}" @click="param.postStatus = 0">
            全部
          </div>
          <div class="filter_item" :class="{filter_item_cur:param.postStatus === 1}" @click="param.postStatus = 1">
            开放中
          </div>
          <div class="filter_item" :class="{filter_item_cur:param.postStatus === 2}" @click="param.postStatus = 2">
            待开放
          </div>
          <div class="filter_item" :class="{filter_item_cur:param.postStatus === 3}" @click="param.postStatus = 3">
            审核失败
<!--            <el-badge :value="3">-->
<!--              审核失败-->
<!--            </el-badge>-->
          </div>
          <div class="filter_item" :class="{filter_item_cur:param.postStatus === 4}" @click="param.postStatus = 4">
            已关闭
          </div>
        </div>
        <div class="search_box">
          <el-input v-model="param.postName" placeholder="请输入您要查询的内容"/><el-button type="primary" @click="getJobList">搜索</el-button>
        </div>
      </div>
    </div>
    <div class="job_list">
      <div class="job_card add_job_card" @click="goUrl"><div>+</div><div style="font-size:20px;">发布职位</div></div>
      <job-card v-for="item in jobList" :item="item" :key="item.postName"></job-card>
<!--      <job-card></job-card>-->
<!--      <job-card></job-card>-->
<!--      <job-card></job-card>-->
    </div>
    <div style="text-align: center;margin-top:20px;">
      <el-pagination style="display:flex;justify-content: center;" background layout="prev, pager, next"
                     :page-size="param.pageSize" v-model:current-page="param.pageNum"
                     @current-page="pageChange" :total="total" />
    </div>


  </div>
</div>
</template>

<script>
import { getJobList,getPositionList } from "@/api/company/company";
import JobCard from "@/companyPages/components/jobCard";
export default {
name: "myPosition",
  components: {JobCard},
  data(){
    let that = this;
    return {
      loadComplete:false,
      sortList:[
        {
          label:"创建日期",
          value:"create_date"
        }
      ],
      jobTypeList:[],
      position:[],
      param:{
        "orderByName": "create_date",
        "pageNum": 1,
        "pageSize": 10,
        "postClassificationId": "",
        "postName": "",
        "postStatus": 0
      },
      total:0,
      jobList:[],
      positionProp:{
        lazy:true,
        lazyLoad(node,resolve){
          const {level} = node;
          let nodes = [];
          if(level === 0){
            that.allPositionList.map(v=>{
              if(v.parentId === 0 || v.parentId === '0'){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:false
                });
              }
            });
            nodes.push({
              label:"全部",
              value:0,
              leaf:true
            });
            resolve(nodes);
          }else{
            that.allPositionList.map(v=>{
              if(v.parentId === node.data.value){
                nodes.push({
                  label:v.name,
                  value:v.id,
                  leaf:true
                });
              }
            });
            resolve(nodes);
          }
        }
      },
      allPositionList:[]
    }
  },
  watch:{
    "param.orderByName":{
      handler(v){
        this.param.pageNum=1;
        this.getJobList();
      }
    },
    "param.postStatus":{
      handler(v){
        this.param.pageNum=1;
        this.getJobList();
      }
    }
  },
  methods:{
    pageChange(v){
      this.param.pageNum = v;
      this.getJobList();
    },
    changePosition(v){
      if(this.position.length>1){
        this.param.postClassificationId = this.position[1];
      }else{
        this.param.postClassificationId = "";
      }
      this.param.pageNum=1;
      this.getJobList();
    },
    goUrl(){
      this.$router.push("/editPosition");
    },
    // getPostName(postId){
    //   let name = ""
    //   for(let i in this.allPositionList){
    //     if(this.allPositionList[i].id === postId){
    //       name = this.allPositionList[i].name;
    //       break;
    //     }
    //   }
    //   return name;
    // },
    getJobList(){
      getJobList(this.param).then(res => {
        if(res.code === 0){
          this.jobList = res.data.rows;
          this.total = res.data.total;
        }

      });
    }
  },

  mounted(){
    this.getJobList();
    getPositionList().then(res => {
      this.allPositionList = res.data;
      this.loadComplete=true;

    });
  }
}
</script>

<style lang="less" scoped>
.page_my_position{
  padding-bottom:10px;
}
.job_list{
  display: flex;
  flex-wrap: wrap;
  margin-right:-10px;
  .job_card{
    margin-right:10px;
    margin-bottom:10px;
  }
  .add_job_card{
    display:flex;
    align-items: center;
    cursor:pointer;
    color:#00b0b1;
    justify-content: center;
    flex-direction: column;
    font-size:35px;
  }
}
</style>
